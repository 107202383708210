<template>
  <div
    class="base-modal"
    @click.prevent.self="$emit('close')">
    <div class="modal-window">
      <slot />
      <div
        class="modal-close"
        @click.prevent="$emit('close')">
        <icon
          size="large"
          src="icons/cross.svg">
        </icon>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .base-modal {
    @apply
      z-50
      flex
      top-0
      fixed
      w-full
      h-full
      left-0
      bg-gray-200
      items-center
      bg-opacity-50
      justify-center;

    $margin: 96px;
    $marginMobile: 36px;

    .modal-window {
      @apply
        w-full
        h-full
        relative
        shadow-xl
        text-left
        overflow-y-auto
        bg-custom-secondary
        text-custom-tertiary;

      @media (max-width: 1024px) {
        max-width: calc(100vw - #{$marginMobile});
        max-height: calc(100vh - #{$marginMobile});
      }

      max-width: calc(100vw - #{$margin});
      max-height: calc(100vh - #{$margin});
    }

    .modal-close {
      @apply
        p-2
        flex
        fixed
        top-0
        right-0
        md:top-5
        shadow-lg
        md:right-7
        items-center
        rounded-full
        justify-center
        cursor-pointer
        text-custom-primary
        bg-custom-secondary;
    }
  }
</style>