export default {
  namespaced: true,
  state: {
    user: null,
    authCode: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setAuthCode(state, authCode) {
      state.authCode = authCode
    }
  },
  getters: {
    username: (state) => state.user?.login,
    email: (state) => state.user?.email,
    isAuthenticated: (state) => !!state.user,
    authCode: (state) => state.authCode,
    user: (state) => state.user,
  }
}