<template>
  <div class="centered-form">
    <form class="form">
      <div
        v-if="title"
        class="title">
        {{ title }}
      </div>
      <slot/>
    </form>
  </div>
</template>

<script>
  export default {
    props: [
      'title',
    ]
  }
</script>

<style lang="scss" scoped>
  .centered-form {
    @apply
    p-2
    flex
    md:p-0
    w-full
    h-full
    md:flex-col
    items-center
    text-gray-800
    justify-center
    bg-custom-background;

    .form {
      @apply
      p-6
      h-auto
      w-full
      rounded
      bg-white
      md:border
      max-w-lg
      md:shadow-xl;

      .title {
        @apply
        mb-2
        text-xl
        font-medium;
      }
    }
  }
</style>