<template>
  <div :class="{
      'folder': true,
      'folder--splitviewMode': splitviewMode
    }" @click.prevent="select"
    @mouseenter="onMouseOver"
    @mouseleave="onMouseLeave">
    <div class="preview">
      <img :src="imagePath">
    </div>
    <div
      class="title"
      v-if="!hideName">
      <slot />
    </div>
    <div
      class="tooltip"
      v-if="tooltip && showTooltip">
      {{ tooltip }}
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'element',
      'tooltip',
      'hideName',
      'splitviewMode',
      'emitClickEvent',
    ],
    computed: {
      imagePath() {
        switch (this.element?.media?.doctype) {
          case 'jpg':
          case 'jpeg':
          case 'gif':
          case 'png':
          case 'pdf':
            return this.element
            && this.element.links
            && this.element.links.length > 0
              ? this.element.links.find(link => link.rel === 'thumbnail')?.href
              : 'images/folder.gif'
          case 'mov':
            return this.element
            && this.element.media
            && this.element.media.links
            && this.element.media.links.length > 0
              ? this.element.media.links.find(link => link.rel === 'poster')?.href
              : 'images/folder.gif'
          default:
            return 'images/folder.gif'
        }
      }
    },
    data() {
      return {
        timeoutHandler: null,
        showTooltip: false,
      }
    },
    methods: {
      select($event) {
        if (this.emitClickEvent) {
          return this.$emit('select', $event)
        }
        return this.go(this.$route.path + `/${this.element.categoryname}-${this.element.id}`, {}, {mode: 'push'})
      },
      onMouseOver() {
        clearTimeout(this.timeoutHandler)
        this.timeoutHandler = setTimeout(() => {
          this.showTooltip = true
        }, 1000)
      },
      onMouseLeave() {
        clearTimeout(this.timeoutHandler)
        this.showTooltip = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .folder {
    @apply
      flex-1
      relative
      max-w-full
      select-none
      cursor-pointer
      hover:border-custom-primary;

    &.folder--splitviewMode {
      max-width: 150px;
      min-width: 150px;
      max-height: 100%;
    }

    .preview {
      @apply
        p-3
        flex
        border
        bg-white
        shadow-md
        rounded-sm
        justify-center;

      img {
        @apply
          h-full
          max-h-40
          max-w-full
          select-none;
      }
    }

    .title {
      @apply
        mt-2
        px-2
        break-words;
    }

    .tooltip {
      @apply
        p-2
        top-0
        left-2
        rounded
        absolute
        bg-white
        shadow;
    }
  }
</style>