<template>
  <div :class="{
      'folder-container': true,
      // 'md:grid-cols-1': defaultGridSize === 1,
      // 'md:grid-cols-2': defaultGridSize === 2,
      // 'md:grid-cols-3': defaultGridSize === 3,
      // 'md:grid-cols-4': defaultGridSize === 4,
      // 'md:grid-cols-5': defaultGridSize === 5,
      // 'md:grid-cols-6': defaultGridSize === 6,
      // 'md:grid-cols-7': defaultGridSize === 7,
      // 'md:grid-cols-8': defaultGridSize === 8,
      // 'md:grid-cols-9': defaultGridSize === 9,
      // 'md:grid-cols-10': defaultGridSize === 10,
      'folder-container--horizontal': horizontal,
    }">
    <slot />
  </div>
</template>

<script>
  export default {
    props: [
      'gridSize',
      'horizontal',
    ],
    computed: {
      defaultGridSize() {
        return this.gridSize || 3
      }
    }
  }
</script>

<style lang="scss" scoped>
  .folder-container {
    @apply
      mt-4
      grid
      gap-4
      w-full
      grid-cols-1;
    @media (min-width: 768px) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 188.5px));
    }

    &.folder-container--horizontal {
      @apply
        mt-0
        flex
        w-full
        overflow-x-auto;
    }
  }
</style>