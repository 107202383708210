<template>
  <div class="default-layout">
    <div :class="{
        'component': true,
        'component--wide': wide,
      }">
      <breadcrumbs
        v-if="breadcrumb && !wide">
        <breadcrumb
          :key="bc.id"
          :index="idx"
          :breadcrumb="bc"
          v-for="(bc, idx) in calculatedBreadcrumbs">
          {{ bc.name }}
        </breadcrumb>
      </breadcrumbs>
      <slot/>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'wide',
      'category',
      'breadcrumb',
      'splitviewMode',
    ],
    computed: {
      calculatedBreadcrumbs() {
        let baseBreadcrumb = this.category?.breadcrumbs?.length > 0
          ? `/${this.$helper.slugify(this.category?.breadcrumbs[0].name)}-${this.category?.breadcrumbs[0].id}/`
          : '/'

        if (this.category
          && this.category.breadcrumbs) {
          return this.category.breadcrumbs
            .filter((breadcrumb, index, self) => index > 1 && index < (self.length - 1))
            .map((breadcrumb, index) => {
              if (index > 0) {
                baseBreadcrumb += '/'
              }
              baseBreadcrumb += `${this.$helper.slugify(breadcrumb.name)}-${breadcrumb.id}`
              return {
                id: breadcrumb.id,
                name: breadcrumb.name,
                link: baseBreadcrumb,
              }
            })
        }
      }
    },
    inject: [
      '$helper'
    ]
  }
</script>

<style lang="scss" scoped>
  .default-layout {
    @apply
      flex
      w-full
      h-full
      flex-col
      items-center
      overflow-y-auto;

    .component {
      @apply
        pt-2
        pb-4
        px-2
        h-full
        w-full
        md:pt-12
        max-w-5xl;

      &.component--wide {
        @apply
          p-0
          md:p-0
          max-w-full;
      }
    }
  }
</style>