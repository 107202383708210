<template>
  <div class="navigation-layout">
    <nav
      v-if="showNavigation"
      class="navigation bg-custom-primary">
      <router-link
        :to="resolve(homePath)">
        <img
          :src="logo"
          class="brand">
      </router-link>

      <!-- Up to 3 Items when desktop -->
      <navigation-container
        v-if="navigationEntries && navigationEntries.length > 0">
        <template v-if="!hasMobileView">
          <navigation-entry
            :key="c"
            class="mx-2"
            :link="navigationEntries[c - 1].link"
            v-for="c in maximumVisibleEntries">
            {{ navigationEntries[c - 1].label }}
          </navigation-entry>
        </template>

        <!-- Has more than ${visibleNavigationEntries} entries -->
        <template v-if="navigationEntries.length > visibleNavigationEntries || hasMobileView">
          <div
            class="menu-toggle"
            @click.prevent="toggle">
            <icon
              size="xlarge"
              :key="iconPath"
              :src="iconPath"
            />
          </div>
        </template>
      </navigation-container>

      <div
        class="navigation-entries"
        v-if="showNavigationEntries">
        <navigation-entry
          :key="index"
          :link="entry.link"
          :menu-entry="true"
          v-for="(entry, index) in navigationEntries">
          {{ entry.label }}
        </navigation-entry>
      </div>
    </nav>
    <div :class="{
      'navigation-content': true,
      'navigation-content--no-nav': !showNavigation,
      'navigation-content--no-footer': !showNavigationFooter,
    }">
      <slot/>
      <navigation-footer
        :entries="footerEntries"
        v-if="footerEntries && footerEntries.length > 0"
      />
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'entries',
      'category',
      'footerEntries'
    ],
    computed: {
      ...mapGetters({
        logo: 'app/logo',
        hasMobileView: 'app/hasMobileView',
        visibleNavigationEntries: 'config/visibleNavigationEntries',
      }),
      iconPath() {
        return this.showNavigationEntries
          ? 'icons/cross.svg'
          : 'icons/menu.svg'
      },
      showNavigation() {
        return this.$route.query.showNavigation !== 'false'
      },
      maximumVisibleEntries() {
        return this.entries.length < this.visibleNavigationEntries
          ? this.entries.length
          : this.visibleNavigationEntries
      },
      homePath() {
        return this.$route.params.slug
          ? `${this.entries && this.entries.length > 0 && this.entries[0] ? this.entries[0].link : ''}`
          : '/'
      },
      showNavigationFooter() {
        return this.footerEntries
          && this.footerEntries.length > 0
      }
    },
    data() {
      return {
        navigationEntries: [],
        showNavigationEntries: false,
      }
    },
    methods: {
      toggle() {
        this.showNavigationEntries = !this.showNavigationEntries
      },
      createNavigationEntries() {
        if (!this.entries) {
          return
        }
        this.navigationEntries = [...this.entries]

        let configurationAttributeValue = this.$helper.findAttributeValue(this.category, 'CMS-Configuration')
        if (this.category
          && this.$route.params.slug
          && configurationAttributeValue) {

          const isAuthenticated = this.$store.getters['auth/isAuthenticated']
            && this.$store.getters['auth/username'] !== 'guest'

          // Add authentication entries
          if (configurationAttributeValue.includes('EnableAuthentication')) {
            if (isAuthenticated) {
              this.navigationEntries.push({
                label: this.$t('auth.logout'),
                link: `/${this.$route.params.slug}/auth/logout`
              })
            } else {
              this.navigationEntries.push({
                label: this.$t('auth.login'),
                link: `/${this.$route.params.slug}/auth/login`
              })

              // Add registration entries
              if (configurationAttributeValue.includes('EnableRegister')) {
                this.navigationEntries.push({
                  label: this.$t('auth.register'),
                  link: `/${this.$route.params.slug}/auth/register`
                })
              }
            }
          }
        }
      }
    },
    watch: {
      '$route': {
        deep: true,
        immediate: true,
        handler() {
          this.showNavigationEntries = false
          this.createNavigationEntries()
        }
      },
      entries: {
        deep: true,
        handler() {
          this.createNavigationEntries()
        }
      },
      category: {
        deep: true,
        handler() {
          this.createNavigationEntries()
        }
      }
    },
    inject: [
      '$helper',
    ]
  }
</script>

<style lang="scss" scoped>
  $menuHeight: 56px;

  .navigation-layout {
    @apply
      flex
      w-full
      h-full
      flex-1
      flex-col;

    .navigation {
      @apply
        py-2
        px-4
        md:px-6
        z-20
        flex
        w-full
        relative
        md:static
        shadow-custom
        justify-between;

      .brand {
        @apply
          max-h-10
          max-w-full
          md:max-h-full;
      }

      .menu-toggle {
        @apply
          mx-1
          flex
          cursor-pointer
          text-custom-secondary;
      }

      .navigation-entries {
        @apply
          p-4
          z-50
          fixed
          w-full
          top-16
          right-0
          max-h-full
          opacity-90
          text-right
          md:right-2
          md:max-w-sm
          md:shadow-lg
          md:rounded-b-sm
          overflow-y-auto
          bg-custom-primary;
        @media(max-width: 1024px) {
          height: calc(100vh - #{$menuHeight})
        }
      }
    }

    .navigation-content {
      @apply
        mb-9
        w-full
        overflow-y-auto;
        height: calc(100% - #{$menuHeight});

      &.navigation-content--no-nav {
        @apply
          h-full;
      }

      &.navigation-content--no-footer {
        @apply
          mb-0;
      }
    }
  }
</style>