<template>
  <div class="breadcrumbs">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
  .breadcrumbs {
    @apply
      mb-2;
  }
</style>