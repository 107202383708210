import createPersistedState from 'vuex-persistedstate'
import { createStore } from 'vuex'
import config from './modules/config'
import auth from './modules/auth'
import app from './modules/app'

export default createStore({
  plugins: [createPersistedState({
    paths: [
      'auth.user',
      'auth.authCode',
    ]
  })],
  modules: {
    config,
    auth,
    app,
  }
})
