import {createApp, defineAsyncComponent} from 'vue'
import { createI18n } from 'vue-i18n'
import router from './router'
import store from './store'
import VueRest from './plugins/rest'
import VueHelper from './plugins/helper'
import RouterMixin  from './mixin/router'
import VueToast from '@meforma/vue-toaster'

// Components
import Icon from './components/global/Icon'
import CButton from './components/global/CButton'
import Breadcrumb from './components/Breadcrumb'
import Breadcrumbs from './components/Breadcrumbs'
import FolderElement from './components/FolderElement'
import FormRow from './components/global/forms/FormRow'
import FolderContainer from './components/FolderContainer'
import FormInput from './components/global/forms/FormInput'
import FormMessage from './components/global/forms/FormMessage'
import ClientSelector from './components/navigation/ClientSelector'
import NavigationLayout from './components/layouts/NavigationLayout'
import NavigationEntry from './components/navigation/NavigationEntry'
import DefaultLayout from './components/layouts/content/DefaultLayout'
import CenteredFormLayout from './components/layouts/CenteredFormLayout'
import NavigationContainer from './components/navigation/NavigationContainer'
import NavigationFooter from './components/navigation/NavigationFooter'
import BaseModal from './components/global/modals/BaseModal'
import ContentContainer from './components/ContentContainer'
import ContentSection from './components/ContentSection'

// Language files
import de from './locales/de'
import en from './locales/en'

const run = async () => {
  // Loads the configuration file
  await store.dispatch('config/loadConfiguration')

  // Create i18n-languages module
  const i18n = createI18n({
    locale: store.getters['config/language'],
    fallbackLocale: 'en',
    messages: {
      de,
      en,
    }
  })

  // Run the app
  createApp(defineAsyncComponent(() => import('@/App.vue')))
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueRest)
    .use(VueToast)
    .use(VueHelper)
    .mixin(RouterMixin)
    .component('icon', Icon)
    .component('form-row', FormRow)
    .component('c-button', CButton)
    .component('base-modal', BaseModal)
    .component('form-input', FormInput)
    .component('breadcrumb', Breadcrumb)
    .component('breadcrumbs', Breadcrumbs)
    .component('form-message', FormMessage)
    .component('default-layout', DefaultLayout)
    .component('folder-element', FolderElement)
    .component('client-selector', ClientSelector)
    .component('content-section', ContentSection)
    .component('folder-container', FolderContainer)
    .component('navigation-entry', NavigationEntry)
    .component('navigation-layout', NavigationLayout)
    .component('navigation-footer', NavigationFooter)
    .component('content-container', ContentContainer)
    .component('centered-form-layout', CenteredFormLayout)
    .component('navigation-container', NavigationContainer)
    .mount('#app')
}
run().then(() => console.log('App has been launched'))
