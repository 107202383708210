<template>
  <button
    :class="classes"
    :disabled="disabled">

    <icon
      :src="icon"
      v-if="icon"
      size="large">
    </icon>

    <slot />
  </button>
</template>

<script>
  export default {
    props: [
      'icon',
      'disabled',
    ],
    computed: {
      classes() {
        let classes = []

        if (this.icon) {
          classes.push('button--icon')
        }

        return classes.concat([
          'button',
          'bg-custom-primary',
          'text-custom-secondary',
          'hover:bg-custom-secondary',
          'hover:text-custom-primary'
        ]).join(' ')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .button {
    @apply
      py-2
      px-4
      w-full
      text-sm
      rounded
      shadow-lg
      cursor-pointer;

    &.button--icon {
      @apply px-2;
    }
  }
</style>