import {defineAsyncComponent, markRaw} from 'vue'
import $router from '../router'

export default {
  install(app, options) {
    app.provide('$helper', {
      slugify(str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
        let to = "aaaaeeeeiiiioooouuuunc------";
        for (let i = 0, l = from.length; i < l; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        return str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes
      },
      /**
       * Helper to retrieve id from slug
       * @param slug
       * @return {string|null}
       */
      extractIdFromSlug(slug) {
        if (!slug) {
          return undefined
        }
        return slug.substring(slug.lastIndexOf('-') + 1)
      },
      /**
       * Extracts the property value from attribute
       * @param attribute
       * @param property
       * @return {Promise<string|string|*|string>}
       */
      extractPropertyFromAttribute(attribute, property) {
        if (attribute && attribute.startsWith(`${property}:`)) {
          return attribute.substr(attribute.lastIndexOf(`${property}:`) + (property.length + 1))
        }
      },
      /**
       * Retrieves the value of an attribute by given category and name
       * @param category
       * @param attrName
       * @return {*}
       */
      findAttributeValue(category, attrName) {
        return category?.attributes?.find(attr => (attr.name || '').toLowerCase() === attrName.toLowerCase())?.value
      },
      /**
       * @param category
       * @param attrNames
       */
      findAttributeValues(category, attrNames) {
        let retVal = {}
        for (let i = 0; i < attrNames.length; i++) {
          retVal[attrNames[i]] = this.findAttributeValue(category, attrNames[i])
        }
        return retVal
      },
      /**
       * Resolves the detail component by given category
       * @param category
       * @param templateAttr
       */
      resolveComponentTypeByCategory(category, templateAttr) {
        let component = 'page'
        switch (category.categorytype) {
          case 'media':
            switch (category?.media?.doctype) {
              case 'pdf':
                component = 'pdf'
                break
              case 'mov':
                component = 'video'
                break
              case 'jpg':
              default:
                component = 'page'
                break
            }
            break
          case 'meeting':
            component = 'meeting'
            break
          case 'stream':
            component = 'stream'
            break
          case 'frame':
            if (category.frameopeninnewtab) {
              let element = document.createElement('a')
              if (element) {
                element.href = category.frameUrl
                element.target = '_blank'
                element.click()
                element.remove()
              }
              return $router.back()
            }
            component = 'iframe'
            break
          case 'folder':
            component = 'folder'
            if (templateAttr?.value === 'Page') {
              component = 'page'
            }
            break
        }
        return component
      },
      /**
       * Resolve properties for custom vue component
       * @param dynamicComponents
       * @param layout
       */
      resolveComponents(dynamicComponents, layout) {
        let components = []
        for (let i = 0; i < dynamicComponents.length; i++) {
          let dynamicComponent = dynamicComponents[i]
          if (dynamicComponent) {
            const component = {
              layout,
              showInFullscreen: false,
              category: dynamicComponent.category,
              componentType: dynamicComponent.component,
            }
            switch (dynamicComponent.component) {
              case 'page':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/Page')))
                break
              case 'folder':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/Folders')))
                break
              case 'video':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/Video')))
                break
              case 'iframe':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/Frame')))
                component.showInFullscreen = true
                break
              case 'pdf':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/PDF')))
                component.showInFullscreen = true
                break
              case 'stream':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/Stream')))
                break
              case 'meeting':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/Meeting')))
                component.showInFullscreen = true
                break
              default:
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/404')))
                break
            }
            components.push(component)
          }
        }
        return components
      }
    })
  }
}