<template>
  <span
    class="breadcrumb"
    @click.prevent="go(breadcrumb.link)">
    <span
      class="mr-1"
      v-if="index > 0">
      /
    </span>
    <span class="text">
      <slot />
    </span>
  </span>
</template>

<script>
  export default {
    props: [
      'index',
      'breadcrumb',
    ],
  }
</script>

<style lang="scss" scoped>
  .breadcrumb {
    @apply
      mx-0.5
      cursor-pointer;

      .text {
        @apply
          text-base
          hover:text-custom-primary;
      }

    &:first-child {
      @apply
        ml-0
        mr-0.5;
    }
  }
</style>