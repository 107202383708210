<template>
  <div class="content-container">
    <slot />
    <div
      v-if="title"
      class="title">
      {{ title }}
    </div>
    <div
      v-if="subtitle"
      class="subtitle">
      {{ subtitle }}
    </div>
    <div
      class="text"
      v-if="description">
      {{ description }}
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'title',
      'subtitle',
      'description',
    ]
  }
</script>

<style lang="scss" scoped>
  .content-container {
    @apply
      p-4
      flex
      flex-col;

    .image {
      @apply
        w-full
        max-w-full
        max-h-full;
    }

    .title {
      @apply
        mt-4
        text-3xl
        font-medium
        text-custom-secondary;
    }

    .subtitle {
      @apply
        mt-4
        text-base
        font-bold
        text-custom-secondary;
    }

    .text {
      @apply
        mt-4
        leading-7
        text-left
        text-custom-tertiary;
    }
  }
</style>