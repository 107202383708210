export default {
  namespaced: true,
  state: {
    logo: null,
    background: null,
    primaryColor: null,
    tertiaryColor: null,
    secondaryColor: null,
    lastScreenWidth: 0,
    lastScreenHeight: 0,
  },
  mutations: {
    setScreenDimensions(state, screenDimensions) {
      state.lastScreenWidth = screenDimensions.width
      state.lastScreenHeight = screenDimensions.height
    },
    setColors(state, colors) {
      state.primaryColor = colors.primary
      state.tertiaryColor = colors.tertiary
      state.secondaryColor = colors.secondary
    },
    setBackground(state, background) {
      state.background = background
    },
    setLogo(state, logo) {
      state.logo = logo
    }
  },
  getters: {
    hasMobileView: (state) => state.lastScreenWidth <= 1024,
    screenHeight: (state) => state.lastScreenHeight,
    screenWidth: (state) => state.lastScreenWidth,
    secondaryColor: (state) => state.secondaryColor,
    tertiaryColor: (state) => state.tertiaryColor,
    primaryColor: (state) => state.primaryColor,
    background: (state) => state.background,
    logo: (state) => state.logo,
  }
}