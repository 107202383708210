export default {
  data() {
    return {
      applicationQueryParams: [
        'showNavigation'
      ]
    }
  },
  methods: {
    prepareQueryParams() {
      if (this.$route && this.$route.query) {
        return Object.keys(this.$route.query)
        .filter(q => this.applicationQueryParams.includes(q))
        .map(q => {
          return {[q]: this.$route.query[q]}
        })
        .reduce((previousValue, accumulator,) => Object.assign({}, previousValue, accumulator), {})
      }
    },
    go(link, query, options) {
      let method = options?.mode || 'replace'
      return this.$router[method]({
        path: link,
        query: Object.assign({}, query, this.prepareQueryParams())
      })
    },
    resolve(link, query) {
      return this.$router.resolve({
        path: link,
        query: Object.assign({}, query, this.prepareQueryParams())
      })
    }
  }
}