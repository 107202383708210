import store from '../store'

export default {
  /**
   * Installs the swagger client to every component in this application.
   * @param app
   * @param options
   * @return {Promise<void>}
   */
  async install(app, options) {
    app.provide('$api', {
      /**
       *  Prepares auth values for swagger client and
       *  returns authorization header
       */
      useHeaders(headers) {
        let auth = store.getters['auth/authCode']
        if (!auth) {
          let username = 'guest'
          let password = 'guest'
          auth = btoa(username + ':' + password)
        }

        return Object.assign({}, {
          'Accept': 'application/json',
          'Authorization': `Basic ${auth}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        }, headers || {})
      },
      /**
       * Retrieves the profile after login
       * @param username
       * @param password
       * @return {*}
       */
      login(username, password) {
        return fetch(store.getters['config/restURI'] + '/profile', {
          headers: {
            'Authorization': 'Basic ' + btoa(username + ":" + password),
            'Accept': 'application/json',
          }
        })
      },
      /**
       * Requests a password reset
       */
      requestPasswordReset(username, jumpURL) {
        // Reset current login
        store.commit('auth/setUser', null)
        store.commit('auth/setAuthCode', null)

        return fetch(store.getters['config/restURI'] + '/newpassworddata', {
          headers: this.useHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          method: 'POST',
          body: `login=${username}&htmlaction=${jumpURL}&htmlisvalid=${store.getters['config/passwordResetValidDays']}`
        })
      },
      /**
       * Send password request mail
       * @param properties
       * @return {Promise<Response>|Promise<string>}
       */
      sendPasswordResetMail(properties) {
        let mailServer = store.getters['config/mailservers']
          && properties.customerId
          ? store.getters['config/mailservers'][properties.customerId]
          : store.getters['config/mailservers']['default']
        if (!mailServer) {
          return Promise.resolve('No mailserver found')
        }

        return fetch(store.getters['config/frontendURI'] + '/auth/password', {
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({
            url: properties.url,
            from: mailServer.from,
            email: properties.email,
            primaryColor: properties.primaryColor,
            tertiaryColor: properties.tertiaryColor,
            secondaryColor: properties.secondaryColor,
            smtpPort: mailServer.smtpPort,
            smtpServer: mailServer.smtpServer,
            smtpPassword: mailServer.smtpPassword,
            smtpUsername: mailServer.smtpUsername,
          })
        })
      },
      /**
       * Set a new password
       * @param resetID
       * @param password
       * @param passwordConfirm
       */
      setPassword(resetID, password) {
        return fetch(store.getters['config/restURI'] + '/setpassword/' + resetID, {
          headers: this.useHeaders({
            'Accept': 'text/plain',
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          method: 'POST',
          body: `password=${password}`
        })
      },
      /**
       * Creates a new user
       * @param payload as Object
       * @param jumpURL
       * @param registerRole
       */
      register(payload, jumpURL, registerRole) {
        const roleId = (typeof registerRole === 'string' || typeof registerRole === 'number')
          ? registerRole
          : registerRole.id
        let validFrom = null
        let validTo = null
        if (typeof registerRole === 'object') {
          validFrom = registerRole.valid_from
          validTo = registerRole.valid_to
        }

        return fetch(store.getters['config/restURI'] + '/register', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Authorization': 'Basic ' + btoa(store.getters['config/registerUser'] + ":" + store.getters['config/registerUser']),
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `invite=yes&data=${JSON.stringify({
            mainRole: roleId,
            email: payload.email,
            login: payload.login,
            company: payload.company,
            lastName: payload.lastname,
            firstName: payload.firstName,
            locale: store.getters['config/language'],
          })}&jump=${jumpURL}`
        })
      },
      /**
       * Retrieves a categories by given parent id
       * @param parentId
       */
      async findCategories(parentId) {
        const query = '?q=parent:' + parentId
        let categoriesResponse = await fetch(store.getters['config/restURI'] + '/categories' + query, {
          headers: this.useHeaders()
        })
        if (categoriesResponse) {
          categoriesResponse = await categoriesResponse.json()
          return categoriesResponse.results
        }
      },
      /**
       *  Retrieves a category by given id
       */
      async findCategory(id) {
        let categoryResponse = await fetch(store.getters['config/restURI'] + '/category/' + id, {
          headers: this.useHeaders()
        })
        if (categoryResponse) {
          categoryResponse = await categoryResponse.json()
          return categoryResponse
        }
      }
    })
  }
}