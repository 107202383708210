export default {
  auth: {
    login: 'Login',
    register: 'Registrieren',
    register_success: 'Erfolgreich registriert!',
    register_success_message: 'Wir haben Ihnen eine E-Mail gesendet. Hinterlegen Sie ein Passwort und loggen Sie sich ein.',
    forgot: 'Passwort vergessen?',
    login_as_guest: 'Als Gast anmelden',
    reset_password: 'Passwort zurücksetzen',
    authenticate_error_message: 'Es ist leider ein Fehler aufgetreten. Überprüfen Sie die Eingabefelder.',
    reset_password_message: 'Ihr Passwort wurde erfolgreich zurückgesetzt. Wir haben Ihnen eine E-Mail gesendet. Klicken Sie auf den Link in der E-Mail und vergeben Sie ein neues Passwort.',
    forgot_subject: 'Setzen Sie ihr neues Passwort',
    set_password: 'Passwort setzen',
    set_password_confirm_failed: 'Die beiden Passwörter sind nicht identisch.',
    set_password_success_message: 'Das Passwort wurde erfolgreich geändert.',
    invalid_request: 'Das Passwort kann nicht zurückgesetzt werden, bitte fordern Sie ein neues Passwort an.',
    logout: 'Abmelden',
  },
  generic: {
    role: 'Rolle',
    title: 'Titel',
    email: 'E-Mail',
    firstname: 'Vorname',
    lastname: 'Nachname',
    company: 'Unternehmen',
    username: 'Benutzername',
    password: 'Passwort',
    password_confirm: 'Passwort bestätigen'
  },
  clientSelector: {
    no_projects_found: 'Es wurden keine Projekte gefunden',
    projects: 'Projekte'
  }
}