<template>
  <div class="input-container">
    <label
      class="label"
      v-if="$slots.default">
      <slot/>
    </label>
    <input
      :type="type"
      class="input"
      :value="modelValue"
      v-if="!isCustomType"
      :required="required"
      :placeholder="placeholder"
      @change="$emit('update:modelValue', $event.target.value)"
    />
    <select
      class="input"
      :value="modelValue"
      v-if="type === 'select'"
      @input="$emit('update:modelValue', $event.target.value)">
      <option
        :key="value.id"
        :value="value.id"
        v-for="value in values">
        {{ value.label }}
      </option>
    </select>
  </div>
</template>

<script>
  export default {
    props: [
      'type',
      'values',
      'required',
      'modelValue',
      'placeholder',
    ],
    computed: {
      isCustomType() {
        return this.type === 'select'
      }
    },
    created() {
      if (this.values
        && this.values.length > 0
        && !this.modelValue) {
        this.$emit('update:modelValue', this.values[0].id)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .input-container {
    @apply
      flex
      w-full
      flex-col;

    .label {
      @apply
        mb-2
        text-sm
        font-medium;
    }

    .input {
      @apply
        px-2
        py-2
        border
        rounded
        text-sm;

      &::placeholder {
        @apply
          text-sm;
      }
    }
  }
</style>